import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import TransitionLink from "gatsby-plugin-transition-link"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

export const Projects = ({ transitionStatus, data }) => {
  gsap.registerPlugin(ScrollTrigger)

  const containerRef = useRef(null)
  const titleRef = useRef(null)
  const itemsRef = useRef(null)

  useEffect(() => {
    const tl = gsap.timeline({ scrollTrigger: itemsRef.current })

    tl.from(titleRef.current, {
      overflow: "hidden",
      textIndent: "-200px",
    }).from(itemsRef.current.children, {
      height: 0,
      duration: 1,
      stagger: 0.2,
    })
  }, [])

  useEffect(() => {
    if (transitionStatus === "exiting") {
      gsap.to(containerRef.current, {
        opacity: 0,
      })
    }
  }, [transitionStatus])

  return (
    <Container ref={containerRef}>
      <Wrapper>
        <SectionTitle ref={titleRef}>Nossos projetos</SectionTitle>
        <DataContainer ref={itemsRef}>
          {data.allFile.nodes.map((project, key) => (
            <Project
              key={key}
              alt="Imagem de projeto"
              image={project.childImageSharp.fluid.src}
            />
          ))}

          <ShowMore
            to="/projetos"
            exit={{
              length: 1,
            }}
            entry={{ length: 1, delay: 1, state: { animateHeader: false } }}
          >
            Ver mais
          </ShowMore>
        </DataContainer>
      </Wrapper>
    </Container>
  )
}

const Container = styled.section`
  width: 100%;
  margin: 50px 0;
`

const Wrapper = styled.div`
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
`

const SectionTitle = styled.h2`
  position: relative;
  margin-left: 10px;
  margin-bottom: 30px;
  text-transform: uppercase;
  &::before {
    content: "";
    width: 5px;
    height: 100%;
    position: absolute;
    top: 0;
    left: -10px;
    background: var(--primary-red-color);
    transition: all 300ms ease-in-out;
  }
`

const DataContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 200px);
  gap: 20px;

  ${media.lessThan("medium")`
        grid-template-rows: repeat(3, 100px);
    `}

  ${media.lessThan("small")`
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(5, 100px);
        grid-auto-flow: row
    `}
`

const Project = styled.div`
  background: url(${props => props.image});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: relative;

  &:nth-child(1) {
    grid-column: 1 / 4;
    grid-row: 1 / 4;

    ${media.lessThan("small")`
            grid-column: 1 / 3;
            grid-row: 1 / 3;
        `}
  }

  /* &::before {
    content: "${props => props.name}";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: red;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: var(--primary-light-color);
    background: linear-gradient(
      20deg,
      rgba(39, 39, 39, 1) 0%,
      rgba(39, 39, 39, 0) 20%
    );

    font-size: 1em;
    font-weight: 900;
    text-indent: 10px;

    opacity: 0;
    transition: all 300ms ease-in-out;
  } */

  &:nth-child(1)::before {
    font-size: 2.5em;
    text-indent: 20px;
  }

  &:hover::before {
    opacity: 1;
  }

  ${media.lessThan("medium")`
        &::before {
            opacity: 1;
        }
    `}
`

const ShowMore = styled(TransitionLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-dark-color);
  color: var(--primary-light-color);
  text-decoration: none;
`

import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { Link } from "gatsby"
import gsap from "gsap"
import { StaticImage } from "gatsby-plugin-image"

export const About = ({ transitionStatus }) => {
  const containerRef = useRef(null)

  const titleRef = useRef(null)
  const textRef = useRef(null)

  useEffect(() => {
    const tl = gsap.timeline({ scrollTrigger: containerRef.current })
    tl.from(titleRef.current, {
      textIndent: "-100%",
      duration: 1,
      overflow: "hidden",
      onComplete: () => {
        titleRef.current?.classList.add("animated")
      },
    }).from(textRef.current.children, {
      y: 100,
      opacity: 0,
      duration: 0.7,
      stagger: 0.2,
    })
  }, [])

  useEffect(() => {
    if (transitionStatus === "exiting") {
      gsap.to(containerRef.current, {
        opacity: 0,
      })
    }
  }, [transitionStatus])
  return (
    <Container ref={containerRef}>
      <Wrapper>
        <SectionTitle ref={titleRef}>
          Sobre a <span>CROSS</span>
        </SectionTitle>
        <DataContainer ref={textRef}>
          <SubSection>
            <SubSectionTitle>Construindo seu sonho</SubSectionTitle>
            <p>
              A Cross é uma das empresas de projeto, arquitetura e construção
              mais antigas de Peruíbe, atuando desde 1979. Já ajudamos a
              construir boa parte da história da cidade e temos muito orgulho de
              cativar até hoje a fidelidade dos clientes, de geração em geração.
            </p>
            <Link to="/sobre">Clique para saber mais</Link>
          </SubSection>
          <SubSection>
            <SubSectionTitle>Expecialistas em:</SubSectionTitle>
            <ul>
              <li>Projeto</li>
              <li>Arquitetura</li>
              <li>Construção</li>
              <li>Regularização de documentação do imóvel</li>
              <li>3D</li>
            </ul>
          </SubSection>
          <SubSection>
            <StaticImage src="../../images/entrada.png" />
          </SubSection>
        </DataContainer>
      </Wrapper>
    </Container>
  )
}

const Container = styled.section`
  width: 100%;
`

const Wrapper = styled.div`
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
`

const SectionTitle = styled.h2`
  position: relative;
  margin-left: 10px;
  text-transform: uppercase;
  &::before {
    content: "";
    width: 5px;
    height: 100%;
    position: absolute;
    top: 0;
    left: -10px;
    background: var(--primary-red-color);
  }

  span {
    font-weight: 900;
  }
`

const DataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const SubSection = styled.div`
  ${media.greaterThan("large")`
        max-width: 250px;
    `}
  width: 100%;
  margin: 30px 0;
  filter: grayscale(100%);

  p {
    margin: 10px 0;
  }

  ul {
    list-style: none;
  }

  li {
    margin: 10px 0;
  }
`

const SubSectionTitle = styled.h3``

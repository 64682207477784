import React, { useEffect } from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import * as Index from "../components/Index"

const IndexPage = ({ transitionStatus, entry, data }) => {
  useEffect(() => {
    if (transitionStatus === "entering") {
    }
    if (transitionStatus === "exiting") {
    }
  }, [transitionStatus])

  return (
    <Layout transitionStatus={transitionStatus} entry={entry}>
      <SEO title="Home" />
      <Index.Welcome transitionStatus={transitionStatus} />
      <Index.SecundaryTitle transitionStatus={transitionStatus} />
      <Index.About transitionStatus={transitionStatus} />
      <Index.Projects transitionStatus={transitionStatus} data={data} />
      <Index.Opinion transitionStatus={transitionStatus} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allFile(filter: { absolutePath: { regex: "/projectsImages/" } }, limit: 6) {
      nodes {
        childImageSharp {
          fluid(maxHeight: 500, maxWidth: 500) {
            src
          }
        }
      }
    }
  }
`

export default IndexPage

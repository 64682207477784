import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import gsap from "gsap"

import osvaldo from "../../comentsImages/osvaldo-carvalho.png"
import anaLucia from "../../comentsImages/ana-lucia-gomes.png"

export const Opinion = ({ transitionStatus }) => {
  const containerRef = useRef(null)
  const titleRef = useRef(null)

  const itemsRef = useRef([])
  itemsRef.current = []

  useEffect(() => {
    const tl = gsap.timeline({ scrollTrigger: containerRef.current })

    tl.from(titleRef.current, {
      overflow: "hidden",
      textIndent: "-200px",
    }).from(itemsRef.current, {
      opacity: 0,
      y: 100,
      duration: 1,
      stagger: 0.2,
    })
  }, [])

  useEffect(() => {
    if (transitionStatus === "exiting") {
      gsap.to(containerRef.current, {
        opacity: 0,
      })
    }
  }, [transitionStatus])

  const addToRef = el => {
    if (el && !itemsRef.current.includes(el)) {
      itemsRef.current.push(el)
    }
  }

  return (
    <Container ref={containerRef}>
      <Wrapper>
        <SectionTitle ref={titleRef}>opinião dos nossos clientes</SectionTitle>
        <DataContainer>
          <OpinionCard
            ref={addToRef}
            author="Osvaldo Carvalho"
            alt="Foto do cliente"
          >
            <ImageWrapper>
              <img src={osvaldo} alt="Foto de Osvaldo Carvalho" />
            </ImageWrapper>
            <p>
              Conhecemos a CROSS desde que chegamos em Peruíbe no início de
              2007. De lá para cá, construímos em Peruíbe mais e uma dezena de
              casas e dois predinhos comercial/residencial, alguns para venda e
              outros para aluguel, todos eles, sem exceção, com os projetos e
              construção da CROSS. A CROSS alia a qualidade do serviço prestado
              à confiança que nela podemos depositar, e disso somos testemunhas
              nesses últimos 14 anos, haja vista, é preciso dizer, a história da
              CROSS já passar de 3 décadas com projetos e construção em Peruíbe,
              e até em cidades vizinhas.
            </p>
          </OpinionCard>
          <OpinionCard
            ref={addToRef}
            author="Ana Lucia Gomes"
            alt="Foto do cliente"
          >
            <ImageWrapper>
              <img src={anaLucia} alt="Foto de Ana Lucia Gomes" />
            </ImageWrapper>
            <p>
              “ Construir ou reformar casas é muito mais que fazer a fundação,
              levantar paredes e colocar pisos e telhados. É colocar o coração
              em cada etapa da obra, sempre pensando no Bem Estar das pessoas
              que irão morar ali.
              <br /> Assim é o trabalho da Cross, que há mais de 40 anos
              transforma casas em lares. “
            </p>
          </OpinionCard>
        </DataContainer>
      </Wrapper>
    </Container>
  )
}

const Container = styled.section`
  width: 100%;
  margin: 100px 0;
`

const Wrapper = styled.div`
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
`

const SectionTitle = styled.h2`
  position: relative;
  margin-left: 10px;
  text-transform: uppercase;
  &::before {
    content: "";
    width: 5px;
    height: 100%;
    position: absolute;
    top: 0;
    left: -10px;
    background: var(--primary-red-color);
  }

  span {
    font-weight: 900;
  }
`

const DataContainer = styled.div`
  margin-top: 30px;
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${media.lessThan("medium")`
        /* grid-template-columns: 1fr; */
    `}
`

const OpinionCard = styled.div`
  display: flex;
  margin-top: 15px;
  margin-bottom: 10px;
  background: var(--secundary-light-color);
  position: relative;

  p {
    padding: 15px;
  }

  &::before {
    content: "${props => props.author}";
    padding: 5px 30px;
    background: var(--primary-dark-color);
    position: absolute;
    bottom: -15px;
    right: 0;
    color: var(--primary-light-color);
    font-size: 0.8em;
  }
`

const ImageWrapper = styled.div`
  margin-top: -15px;
  width: 100%;
  max-width: 100px;
  min-width: 100px;
  img {
    width: 100%;
  }
`

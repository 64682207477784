import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import arrow from "../../images/arrow.svg"
import { StaticImage } from "gatsby-plugin-image"
import gsap from "gsap"
import { Power3 } from "gsap"

export const Welcome = ({ transitionStatus }) => {
  const primaryTitleRef = useRef(null)
  const secundaryTitleRef = useRef(null)
  const terciaryTitleRef = useRef(null)

  const subtitleRef = useRef(null)

  const arrowRef = useRef(null)

  const imageRef = useRef(null)

  useEffect(() => {
    const title = [
      primaryTitleRef.current,
      secundaryTitleRef.current,
      terciaryTitleRef.current,
    ]

    const tl = gsap.timeline({ defaults: { ease: Power3.easeOut } })

    tl.from(title, { y: 100, duration: 1, opacity: 0, stagger: 0.25 })
      .from(subtitleRef.current, { duration: 1, opacity: 0 })
      .from(arrowRef.current, { duration: 1, opacity: 0, y: -50 }, "<-0.5")
      .from(imageRef.current, { duration: 1, opacity: 0, x: 100 }, "1")
  }, [])

  useEffect(() => {
    if (transitionStatus === "exiting") {
      const title = [
        primaryTitleRef.current,
        secundaryTitleRef.current,
        terciaryTitleRef.current,
      ]

      const tl = gsap.timeline({ defaults: { ease: Power3.easeOut } })

      tl.to(title, { y: -100, duration: 0.3, opacity: 0, stagger: 0.1 })
        .to(subtitleRef.current, { duration: 0.3, opacity: 0 }, "<")
        .to(arrowRef.current, { duration: 1, opacity: 0, y: 50 }, "<-0.5")
        .to(imageRef.current, { duration: 1, opacity: 0, x: 100 }, "<")
    }
  }, [transitionStatus])

  return (
    <Container>
      <Wrapper>
        <TextWrapper>
          <Title>
            <span ref={primaryTitleRef}>Construir,</span>
            <span ref={secundaryTitleRef}>reformar e</span>
            <span ref={terciaryTitleRef}>realizar</span>
          </Title>
          <Subtitle ref={subtitleRef}>
            Nós não fazemos casas. Fazemos lares
          </Subtitle>
          <img src={arrow} ref={arrowRef} alt="Seta para baixo" />
        </TextWrapper>
        <ImageWrapper ref={imageRef}>
          <StaticImage src="../../images/banner.jpeg" />
        </ImageWrapper>
      </Wrapper>
    </Container>
  )
}
const Container = styled.div`
  width: 100%;
  margin-bottom: 50px;
`

const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  padding-top: 50px;
  max-width: 1024px;

  display: flex;
  justify-content: space-between;

  ${media.lessThan("medium")`
    flex-direction: column;
  `}
`

const TextWrapper = styled.div`
  img {
    margin-top: 50px;
    bottom: 30px;
  }

  ${media.lessThan("medium")`
    img {
      position: absolute;
      right:5vw;
      height: 20vh;
    }
  `}

  ${media.between("540px", "medium")`
    img {
      top: 50px;
    }
  `}
  
  ${media.lessThan("small")`
    img {
      position: absolute;
      left:5vw;
      height: 20vh;
    }
  `}
`

const Title = styled.h2`
  font-size: clamp(3rem, 9vw, 5rem);
  line-height: 95.9%;

  span {
    display: block;
  }
`

const Subtitle = styled.p`
  font-weight: 700;
  font-size: clamp(0.8rem, 1vw, 8rem);
`

const ImageWrapper = styled.div`
  width: 50%;
  img {
    width: 100%;
    height: 450px;
  }

  ${media.lessThan("medium")`
    width: 100%;
    margin: 30px 0;

    img {
      height: 300px;
    }
  `}
`

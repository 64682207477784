import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import gsap from "gsap"

export const SecundaryTitle = ({ transitionStatus }) => {
  const containerRef = useRef(null)

  useEffect(() => {
    gsap.from(containerRef.current, {
      opacity: 0,
      x: -20,
      duration: 1,
      delay: 1,
    })
  }, [])

  useEffect(() => {
    if (transitionStatus === "exiting") {
      gsap.to(containerRef.current, {
        opacity: 0,
      })
    }
  }, [transitionStatus])

  return (
    <Container ref={containerRef}>
      <Wrapper>
        Projeto
        <br />
        Mão-de-obra
        <br />
        Gerenciamento
      </Wrapper>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  margin-bottom: 200px;
`

const Wrapper = styled.div`
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
  font-size: clamp(2rem, 3vw, 5rem);
  font-weight: 900;
`
